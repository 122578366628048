:root {
  --code-color: darkred;
  --code-bg-color: #aaaaaa;
  --code-font-size: 14px;
  --code-line-height: 1.4;
  --scroll-bar-color: #c5c5c5;
  --scroll-bar-bg-color: #f6f6f6;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-bar-color) var(--scroll-bar-bg-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: var(--scroll-bar-bg-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scroll-bar-color);
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-bg-color);
}

body {
  margin: 0;
  font-family: "Open Sans,sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 0 !important;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100%;
}

#root {
  height: 100%;
}

/* Inputs */
.form-control,
.mix-container {
  width: 100%;
  display: block;
  margin: 0 0 2rem 0;
}

.form-label {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
}

.form-input {
  box-sizing: border-box;
  color: #454655;
  background: transparent;
  border: 1px solid #c0c0ca;
  font-size: 14px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  outline: none;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
}

.form-input-required:before {
  content: "*";
  color: #4268f6;
  margin-right: 4px;
}

.form-input-password:focus-within,
.form-input:focus {
  border-color: #4268f6;
  box-shadow: 0 1px 4px 0 rgb(56 202 241 / 58%);
}

.form-input-password {
  box-sizing: border-box;
  color: #454655;
  background: transparent;
  border: 1px solid #c0c0ca;
  font-size: 14px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  outline: none;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 100%;
  display: flex;
  align-items: center;
}

.form-input-password > input {
  width: 100%;
  border: 0;
}

.form-input-password > input:focus-visible {
  outline: none;
}

.mix-control {
  border: 1px solid #c0c0ca;
  border-left: 20px solid #1976d2;
  padding: 30px 30px 10px 30px;
}
.ql-editor {
  min-height: 120px !important;
}

.img-thumbnail {
  height: 100%;
  width: 150px;
  min-width: 150px;
  max-height: 85px;
  object-fit: contain !important;
  max-width: 100%;
  padding: 0.25rem;
  border: 1px solid #dee2e6;
  background-color: #f8f9fe;
  box-shadow: 0 1px 2px rgb(0 0 0 / 8%);
}

/* px-4 py-3 shadow-sm bg-white border border-secondary mb-2 list-unstyled" */

.order-row {
  background-color: "white";
  border: 1px solid #dde1e5;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  margin-bottom: 1rem;
  list-style: none;
  display: flex;
  align-items: center;
  height: 80px;
}
.order-column {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
